import React from "react";

import { Provider } from 'react-redux'
import store from './store/store';


// import { Switch, Route, Redirect } from "react-router-dom";
import { Switch, Route } from "react-router-dom";


// authentication
import Login from "./pages/Login";
// customers
import CustomerDetails from "./pages/Customers/Detail";
import ListCustomers from "./pages/Customers/List";
// service tickets
import ListServiceTickets from "./pages/ServiceTickets/List";

// accounts
// import ListAccounts from "./pages/Accounts/List";

import Home from "./pages/Home";
// import Tables from "./pages/Tables";
// import Billing from "./pages/Billing";
// import Rtl from "./pages/Rtl";
// import Profile from "./pages/Profile";

import Main from "./components/layout/Main";

import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";


function App() {
  return (
    <Provider store={store}>
    <div className="App">
      <Switch>
        
        {/* authentication */}
        <Route path="/" exact component={Login} />
        <Route path="/login/" exact component={Login} />
        
        <Main>
          <Route exact path="/overview" component={Home} />
          <Route exact path="/customer-details/" component={CustomerDetails} />
          <Route exact path="/customers/" component={ListCustomers} />
          <Route exact path="/service-requests/" component={ListServiceTickets} />
          {/* <Route exact path="/accounts" component={ListAccounts} /> */}
          

          {/* <Route exact path="/tables" component={Tables} />
          <Route exact path="/billing" component={Billing} />
          <Route exact path="/rtl" component={Rtl} />
          <Route exact path="/profile" component={Profile} /> */}

          {/* <Route exact path="*" component={Home} /> */}
          {/* <Redirect from="*" to="/dashboard" /> */}
        </Main>
      </Switch>
    </div>
    </Provider>
  );
}

export default App;

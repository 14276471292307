
// import { useState } from "react";
import { Badge, Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { 
  DashboardOutlined,
  TeamOutlined,
  UserOutlined,
  LogoutOutlined,
  LineChartOutlined,
  WalletOutlined,
  SyncOutlined,
  AuditOutlined,
  SnippetsOutlined
} from "@ant-design/icons";
import React from "react";

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  return (
    <React.Fragment >
      <div className="brand">
        {/* <img src={logo} alt="" /> */}
        <span>DiSAN Admin</span>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/overview">
            <span
              className="icon"
              style={{
                background: page === "overview" ? color : "",
              }}
            >
              <DashboardOutlined />
            </span>
            <span className="label">Overview</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="customers">
          <NavLink to="/customer-details/">
            <span
              className="icon"
              style={{
                background: page === "customer-details" ? color : "",
              }}
            >
              <UserOutlined />
            </span>
            <span className="label">Customer Details</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="Service Requests">
          <NavLink to="/service-requests/">
            <span
              className="icon"
              style={{
                background: page === "service-requests" ? color : "",
              }}
            >
              <SnippetsOutlined />
            </span>
            <span className="label">Service Requests <Badge count={98} /></span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="customers">
          <NavLink to="/customers/">
            <span
              className="icon"
              style={{
                background: page === "customers" ? color : "",
              }}
            >
              <UserOutlined />
            </span>
            <span className="label">Customers</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="Groups">
          <NavLink to="/groups/">
            <span
              className="icon"
              style={{
                background: page === "groups" ? color : "",
              }}
            >
              <TeamOutlined />
            </span>
            <span className="label">Groups</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="accounts">
          <NavLink to="/accounts/">
            <span
              className="icon"
              style={{
                background: page === "accounts" ? color : "",
              }}
            >
              <WalletOutlined />
            </span>
            <span className="label">Accounts</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="transactions">
          <NavLink to="/transactions/">
            <span
              className="icon"
              style={{
                background: page === "transactions" ? color : "",
              }}
            >
              <SyncOutlined />
            </span>
            <span className="label">Transactions</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="Insights">
          <NavLink to="/insights/">
            <span
              className="icon"
              style={{
                background: page === "insights" ? color : "",
              }}
            >
              <LineChartOutlined />
            </span>
            <span className="label">Insights</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item className="menu-item-header" key="5">
          Serious things
        </Menu.Item>

        <Menu.Item key="Users">
          <NavLink to="/admin-users/">
            <span
              className="icon"
              style={{
                background: page === "admin-users" ? color : "",
              }}
            >
              <TeamOutlined />
            </span>
            <span className="label">Admin Users</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="Action Logs">
          <NavLink to="/action-logs/">
            <span
              className="icon"
              style={{
                background: page === "action-logs" ? color : "",
              }}
            >
              <AuditOutlined />
            </span>
            <span className="label">Action Logs</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="logout">
          <NavLink to="/login/">
            <span className="icon"> <LogoutOutlined /></span>
            <span className="label">Logout</span>
          </NavLink>
        </Menu.Item>

      </Menu>
      
    </React.Fragment>
  );
}

export default Sidenav;

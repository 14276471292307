
import React, { useState } from 'react';
import { Modal, Form, Input, Button, Select, InputNumber} from 'antd';
import Transactions from '../../logic/transactions';

const ManualTransactionModal = ({ action, accountType, accountId }) => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [ fetching, setFetching ] = useState(false);
    const [ transactionChannel, setTransactionChannel] = useState('cash');
    const [ aggregator, setAggregator ] = useState('Disan');
    const [aggregatorReference, setAggregatorReference] = useState('');

    const toggleModal = () => {
        setVisible(!visible);
    };

    const submitTransaction = async () => {
        setFetching(true);
        await form.validateFields().then(async values => {
            let transactionData = {
                ...values,
                transactionChannel,
                aggregator,
                aggregatorReference,
                aggregatorStatus: 'success',
                mode: 'manual',
                accountId: accountId,
                direction: action === 'Deposit' ? 'in' : 'out',
            }
            await new Transactions(transactionData).create();
            form.resetFields();
            setFetching(false);
            setVisible(false);
        });
        setFetching(false);
    }

    return (
        <>
            <Button onClick={toggleModal} style={{margin: '2px', color: action === 'Deposit' ? 'green' : 'black'}}>{`${action.substring(0,3)}.`}</Button>
            <Modal
                open={visible}
                title={`${action} ${action === 'Deposit' ? 'to' : 'from'} ${accountType} Account`}
                onCancel={()=>{form.resetFields(); setVisible(false)}}
                onOk={submitTransaction}
                okText={action}
                okButtonProps={{loading: fetching, disabled: fetching}}
                cancelButtonProps={{disabled: fetching}}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="transactionChannel"
                        label="Channel"
                        rules={[
                            {
                                required: true,
                                message: 'Please select the channel of the transaction',
                            },
                        ]}
                    >
                        <Select 
                            placeholder="Select a channel" 
                            value={transactionChannel} 
                            onChange={(value) => {setTransactionChannel(value)}}>
                            <Select.Option value="bank">Bank</Select.Option>
                            <Select.Option value="cash">Cash</Select.Option>
                            <Select.Option value="momo">Mobile Money</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="amount"
                        label="Amount"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the new pin',
                            },
                        ]}
                    >
                        <InputNumber
                            defaultValue={1000}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            style={{width: '100%'}}
                            />
                    </Form.Item>

                    <Form.Item
                        name="transactionDate"
                        label="Date of transaction"
                        rules={[
                            {
                                required: true,
                                message: 'Please select the date of the transaction',
                            },
                        ]}
                    >
                        <Input type="date"/>
                    </Form.Item>

                    {/* only show if the transaction channel is momo */}
                    {
                        transactionChannel === 'momo' ?
                        <React.Fragment>
                            <Form.Item
                                name="aggregator"
                                label="Aggregator"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select the aggregator for this transaction',
                                    },
                                ]}
                            >
                                <Select 
                                    placeholder="Select the aggregator" 
                                    value={aggregator} 
                                    onChange={(value) => {setAggregator(value)}}>
                                    <Select.Option value="Disan">Disan</Select.Option>
                                    <Select.Option value="beyonic">Beyonic</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="aggregatorReference"
                                label="Aggregator Reference"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter the aggregator reference',
                                    },
                                ]}
                            >
                                <Input onChange={(e)=>{ setAggregatorReference(e.target.value)}} />
                            </Form.Item>

                        </React.Fragment>
                        :
                        null
                    }
                    

                    <Form.Item
                        name="transactionReason"
                        label="Reason"
                        rules={[
                            {
                                required: true,
                                message: 'Please provide a reason for this transaction',
                            },
                        ]}
                    >
                        <Input.TextArea rows={3} />
                    </Form.Item>


                </Form>
            </Modal>
        </>
    );
};

export default ManualTransactionModal;

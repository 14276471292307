import React, {useState} from 'react';
import {
    Form, Button, Input, InputNumber, Table, Tag, Skeleton
} from "antd";
import Users from "../../logic/users";
import { useSelector } from 'react-redux';
  
function ListCustomers(props) {
    const [showForm, setShowForm] = useState(false);
    const users = useSelector((state) => state.users);
    const [ fetching, setFetching ] = useState(false);
    return (
        <React.Fragment>
            <p onClick={() => {setShowForm(!showForm)}} style={{color: 'green'}}>
                {`${showForm ? 'Hide' : 'Show'} filter form`}
            </p>
          
          { !showForm ? null : <FilterForm {...props} setFetching={setFetching}/> }
          { users.list.length < 1 ? null : <ResultsTable fetching={fetching} /> }
          
        </React.Fragment>
    );
  }
  
  export default ListCustomers;


function FilterForm (props) {
    const submitFilter = async (data) => {
        props.setFetching(true)
        let query = `?`
        if (data.msisdn !== undefined && data.msisdn !== null) { query += `&msisdn=${data.msisdn}`}
        if (data.email !== undefined && data.email !== null) { query += `&email=${data.email}`}
        if (data.date !== undefined && data.data !== null) { query += `&date=${data.date}`}
        if (data.month !== undefined && data.month !== null) { query += `&month=${data.month}`}
        if (data.year !== undefined && data.year !== null) { query += `&year=${data.year}`}
        await new Users(query ).list()
        props.setFetching(false)
    }
    return (
        <Form 
            onFinish={submitFilter}
            layout="horizontal"
            labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                // maxWidth: ,
              }}
            >
            <Form.Item
            label="Phone number"
                name="msisdn"
                rules={[
                // {
                //   required: true,
                //   message: "Please enter the phone number of the customer",
                // },
                ]}
            >
                <Input placeholder="Phone No e.g. 256706087495" />
            </Form.Item>

            <Form.Item
            label="Email address"
                name="email"
                rules={[
                // {
                //   required: true,
                //   message: "Please enter the phone number of the customer",
                // },
                ]}
            >
                <Input placeholder="Email e.g. someone@example.com" />
            </Form.Item>

            <Form.Item
            label="Date of registration"
                name="date"
                rules={[]}
            >
                <InputNumber style={{width: '100%'}} min={1} max={31} placeholder="Date of reg i.e. 1-31" />
            </Form.Item>

            <Form.Item
            label="Month of registration"
                name="month"
                rules={[]}
            >
                <InputNumber style={{width: '100%'}} min={1} max={12} placeholder="Month of reg i.e. 1-12" />
            </Form.Item>

            <Form.Item
            label="Year of registration"
                name="year"
                rules={[]}
            >
                <InputNumber style={{width: '100%'}} min={2023} max={new Date().getFullYear()} placeholder="Year of reg e.g. 2023" />
            </Form.Item>

            <Form.Item
            wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
                <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                // disable={fetching}
                // loading={fetching}
                >
                Search
                </Button>
            </Form.Item>
          </Form>
    )
}

function ResultsTable (props) {
    const users = useSelector((state) => state.users);
    const columns = [
        {title: "MSISDN", dataIndex: "msisdn", key: "msisdn"},
        {title: "Names", dataIndex: "names", key: "names", render: (index, record) => <span>{`${record.firstName !== undefined ? record.firstName : ''} ${record.lastName !== undefined ? record.lastName : ''}`}</span>},
        {title: "Email", dataIndex: "email", key: "email", render: (index, record) => <span>{`${record.email !== undefined ? record.email : ''}`}</span>},
        {title: "Reg. Date", dataIndex: "reg_date", key: "reg_date", render: (index, record) => <span key={index}>{`${record.timeCreated.date} ${record.timeCreated.monthName} ${record.timeCreated.year}`}</span>},
        {title: "Reg. Time", dataIndex: "reg_time", key: "reg_time", render: (index, record) => <span key={index}>{`${record.timeCreated.hour}:${record.timeCreated.minute}`}</span>},
        {title: "Actions", dataIndex: "actions", key: "actions", render: (index, record) => <span key={index}>
            <Tag color="green">Profile</Tag>
            <Tag color="blue">Accounts</Tag>
            <Tag color="orange">Groups</Tag>
            <Tag color="purple">Transactions</Tag>
            <Tag color="red">Reset PIN</Tag>
            <Tag color="red">Disable User</Tag>
        </span>}
    ]
    return(

        <React.Fragment>
            {
                props.fetching ?
                <Skeleton active />
                :
                <React.Fragment>
                    <p style={{marginBottom: '10px'}}>{`${users.list.pagination.totalRecords} Result(s)`}</p>
                    <Table columns={columns} dataSource={users.list.data} pagination={false} size="small"/>
                </React.Fragment>
            }
        

        </React.Fragment>
    )
}
import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    fetching: true,
    details: {}
  },
  reducers: {
    setAuth: (state, action) => {
        state.fetching = false;
        state.details = action.payload;
    },
    }
});

// this is for dispatch
export const { setAuth } = authSlice.actions;

// this is for configureStore
export default authSlice.reducer;
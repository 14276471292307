import { configureStore } from '@reduxjs/toolkit'
import overviewReducer from './reducers/overview';
import authReducer from './reducers/auth';
import usersReducer from './reducers/users';
import serviceTicketsReducer from './reducers/serviceTickets';

export default configureStore({
  reducer: {
    auth: authReducer,
    overview: overviewReducer,
    users: usersReducer,
    tickets: serviceTicketsReducer
  }
});
import { createSlice } from '@reduxjs/toolkit';

export const ticketsSlice = createSlice({
  name: 'serviceTickets',
  initialState: {
    fetching: true,
    list: [],
    pagination: {}
  },
  reducers: {
    setServiceTickets: (state, action) => {
        state.fetching = false;
        state.list = action.payload.data;
        state.pagination = action.payload.pagination;
    },
    }
});

// this is for dispatch
export const { setServiceTickets } = ticketsSlice.actions;

// this is for configureStore
export default ticketsSlice.reducer;
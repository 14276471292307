import store from '../store/store';
import { callApi } from "../apiIntegration";
import { notification } from 'antd';
import { setServiceTickets } from "../store/reducers/serviceTickets";


class ServiceTickets {
    constructor(props, data, ){
        this.props = props;
        this.data = data;
        this.notificationStatus = {
            200: "success",
            400: "error",
            401: "error"
        };
    }

    /**
     * getting the tickets
     */
    async list(){
        const apiResponse = await callApi({
            requireAuth: true,
            method: "GET",
            body: {},
            endpoint: `/api/v1/service-tickets/${this.data}`,
            contentType: "application/json",
          })
          console.log(apiResponse)
        if (apiResponse.status === 200){
            store.dispatch(setServiceTickets(apiResponse))
        } else {
            notification[this.notificationStatus[apiResponse.status]]({
                description: apiResponse.message
            })
        }
    }
}


export default ServiceTickets;
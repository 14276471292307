import { createSlice } from '@reduxjs/toolkit';

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    fetching: true,
    list: [],
    userProfile: {}
  },
  reducers: {
    setUsers: (state, action) => {
        state.fetching = false;
        state.list = action.payload;
    },
    }
});

// this is for dispatch
export const { setUsers } = usersSlice.actions;

// this is for configureStore
export default usersSlice.reducer;
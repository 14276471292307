import { callApi } from "../apiIntegration";
import { notification } from 'antd';


class Transactions {
    constructor(data, ){
        this.data = data;
        this.notificationStatus = {
            200: "success",
            400: "error",
            401: "error"
        };
    }

    /**
     * creates a transaction
     */
    async create(){
        const apiResponse = await callApi({
            requireAuth: true,
            method: "POST",
            body: JSON.stringify(this.data),
            endpoint: `/api/v1/transactions`,
            contentType: "application/json",
        })
        notification[this.notificationStatus[apiResponse.status]]({
            description: apiResponse.message
        })
    }
}

export default Transactions;
import React from 'react';
import {Table, Button, Tag} from 'antd';

const columns = [
    { title: 'Transaction', dataIndex: 'transactionType', key: 'transactionType' },
    { title: 'Account', dataIndex: 'accountType', key: 'accountType' },
    // Fri Oct 27 2023 10:22:06 GMT+0300 (East Africa Time)
    { title: 'Time initiated', dataIndex: 'initiationTime', key: 'initiationTime', render: (initiationTime) => <span>{`${initiationTime.timestamp.substring(0,21)}`}</span> },
    // { title: 'Collected Amt', dataIndex: 'collectableAmount', key: 'collectableAmount', render: (collectableAmount) => <span>collectableAmount !== undefined ? {`UGX ${Number(collectableAmount.$numberDecimal).toLocaleString()}`}: ''</span> },
    { title: 'Amount', dataIndex: 'transactionAmount', key: 'transactionAmount', render: (transactionAmount) => <span>{`UGX ${Number(transactionAmount.$numberDecimal).toLocaleString()}`}</span> },
    { title: 'Status', dataIndex: 'status', key: 'status', render: (status) => <Tag color={status==='Success' ? 'green': status === 'Failed' ? 'red' : 'blue'}>{status}</Tag>},
    {title: 'Actions', key: 'action', render: (text, record) => 
        <React.Fragment>
        <Button size="small">Report</Button>
        </React.Fragment>
    }
]

// {
//     "_id": "653b651faaf9dc9fa665b048",
//     "accountId": "63f15094290030489e03293d",
//     "accountType": "Savings",
//     "ownerId": "63f15093290030489e03293c",
//     "aggregator": "beyonic",
//     "aggregatorReference": "none",
//     "aggregatorStatus": "success",
//     "transactionAmount": {
//         "$numberDecimal": "2000"
//     },
//     "msisdn": "256706087495",
//     "initiationTime": {
//         "date": 27,
//         "dayName": "Friday",
//         "month": 10,
//         "monthName": "October",
//         "year": 2023,
//         "hour": 10,
//         "minute": 22,
//         "timeSeconds": 1698391326201,
//         "timestamp": "Fri Oct 27 2023 10:22:06 GMT+0300 (East Africa Time)"
//     },
//     "status": "Success",
//     "transactionType": "Withdraw",
//     "transactionChannel": "momo",
//     "collectableAmount": {
//         "$numberDecimal": "2000"
//     },
//     "direction": "out",
//     "manualTransactionDate": "2023-10-12",
//     "transactionReason": "Testing manual withdraw",
//     "manualRecord": true,
//     "user": {
//         "id": "6423e2d6685e6c813d2994ff",
//         "name": "Esau Lwanga"
//     },
//     "processingStatus": "Done",
//     "balanceTracker": {
//         "balanceBefore": {
//             "$numberDecimal": "12069.25"
//         },
//         "balanceAfter": {
//             "$numberDecimal": "10069.25"
//         },
//         "availableBalanceBefore": {
//             "$numberDecimal": "12069.25"
//         },
//         "availableBalanceAfter": {
//             "$numberDecimal": "9069.25"
//         }
//     },
//     "lastUpdateReason": "Transaction processed",
//     "lastUpdated": {
//         "date": 27,
//         "dayName": "Friday",
//         "month": 10,
//         "monthName": "October",
//         "year": 2023,
//         "hour": 10,
//         "minute": 31,
//         "timeSeconds": 1698391917469,
//         "timestamp": "Fri Oct 27 2023 10:31:57 GMT+0300 (East Africa Time)"
//     },
//     "collectedCharges": true
// },

const RecentTransactionsTable = ({ transactions }) => {
    return (
        <Table
            dataSource={transactions}
            columns={columns}
            pagination={false}
        />
    );
};

export default RecentTransactionsTable;

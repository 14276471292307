import { createSlice } from '@reduxjs/toolkit';

export const overviewSlice = createSlice({
  name: 'overview',
  initialState: {
    fetching: true,
    details: []
  },
  reducers: {
    setOverview: (state, action) => {
        state.fetching = false;
        state.details = action.payload;
    },
    }
});

// this is for dispatch
export const { setOverview } = overviewSlice.actions;

// this is for configureStore
export default overviewSlice.reducer;
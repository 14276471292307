
import React, { useState } from 'react';
import { Modal, Form, Input, Button} from 'antd';
import Users from '../../logic/users';

const ResetPinModal = ({ userId, msisdn }) => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [ fetching, setFetching ] = useState(false);

    const toggleModal = () => {
        setVisible(!visible);
    };

    const resetPin = async () => {
        setFetching(true);
        form.validateFields().then(async values => {
            // wait to reset the user pin
            const user = new Users({
                userId: userId,
                pin: values.pin,
            });
            await user.resetPin();
            form.resetFields();
            setVisible(false);
        });
        setFetching(false);
    }

    return (
        <>
            <Button onClick={toggleModal}>Reset Pin</Button>
            <Modal
                open={visible}
                title={`Reset Pin for User ${msisdn}`}
                onCancel={()=>{form.resetFields(); setVisible(false)}}
                onOk={resetPin}
                okText="Reset Pin"
                okButtonProps={{loading: fetching, disabled: fetching}}
                cancelButtonProps={{disabled: fetching}}
            >
                <Form form={form} layout="vertical" onSubmit={resetPin}>
                    <Form.Item
                        name="pin"
                        label="New Pin"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the new pin',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ResetPinModal;

import React from 'react';
import {Table, Button} from 'antd';
import ManualTransactionModal from '../../ActionModals/ManualTransaction';

const columns = [
    { title: 'Account', dataIndex: 'accountType', key: 'accountType' },
    { title: 'Created', dataIndex: 'timeCreated', key: 'timeCreated', render: (timeCreated) => <span>{`${timeCreated.timestamp.substring(0,21)}`}</span> },
    { title: 'Saving Period', dataIndex: 'savingPeriod', key: 'savingPeriod', },
    { title: 'Maturity', dataIndex: 'maturityDate', key: 'maturityDate', render: (maturityDate) => <span>{`${maturityDate.dayName} ${maturityDate.date} ${maturityDate.monthName} ${maturityDate.year}`}</span> },
    { title: 'Actual Balance', dataIndex: 'actualBalance', key: 'actualBalance', render: (actualBalance) => <span>{`UGX ${Number(actualBalance.$numberDecimal).toLocaleString()}`}</span> },
    { title: 'Available Balance', dataIndex: 'availableBalance', key: 'availableBalance', render: (availableBalance) => <span>{`UGX ${Number(availableBalance.$numberDecimal).toLocaleString()}`}</span> },
    {title: 'Actions', key: 'action', render: (text, record) => 
        <React.Fragment>
            <ManualTransactionModal action="Deposit" accountType={record.accountType} accountId={record._id} />
            <ManualTransactionModal action="Withdraw" accountType={record.accountType} accountId={record._id}/>
        <Button size="small" style={{margin: '2px'}} danger>Lock</Button>
        </React.Fragment>
    }
]

const AccountsTable = ({ accounts }) => {
    return (
        <Table
            dataSource={accounts}
            columns={columns}
            pagination={false}
        />
    );
};

export default AccountsTable;

import React from 'react';
import {Col, Row, Button, Tag} from 'antd';
import ResetPinModal from '../../ActionModals/ResetPin';

const Profile = ({ customer }) => {
    return (
        // show the details of a user including a profile picture
        // and other details
        <React.Fragment style={{width: '100%', marginTop: '50px'}}>
            <Row border={1} gutter={5} style={{marginBottom: '10px'}}>    
                <Col sm={24} md={8} lg={8}>
                    <img src="https://via.placeholder.com/250" alt="profile" /><br/>
                    <Tag color="green" style={{marginTop: '20px'}}>Upload picture</Tag>
                </Col>
                <Col sm={24} md={12} lg={12}>
                    <h2>{customer.msisdn}</h2>
                    <h3>{customer.name === undefined ? 'No Name Yet' : customer.name}</h3>
                    <p>Date of Birth: {customer.dob}</p>
                    <p>Email: {customer.email}</p>
                    <p>Address: {customer.address}</p>
                    <p>Date Joined: {customer.timeCreated.timestamp}</p>
                </Col>

                <Col sm={24} md={4} lg={4}>
                    <Row><Button size="small">Edit Details</Button></Row>
                    <Row style={{marginTop: '5px'}}>
                    <ResetPinModal userId={customer._id} msisdn={customer.msisdn} />
                    </Row>
                    
                    <Row style={{marginTop: '5px'}}><Button danger size="small">Disable User</Button></Row>
                </Col>
            </Row>
            <hr/>

            <Row>
                {/* table showinf various financial statuses of the customer */}
                <Col sm={24} md={24} lg={24}>
                    <h4>Activity insight</h4>
                    <table border="1">
                        <tbody>
                            <tr>
                                <td>No. accounts</td>
                                <td>{Number(customer.no_accounts).toLocaleString()}</td>
                            </tr>
                            <tr>
                                <td>No. transactions</td>
                                <td>{Number(customer.no_transactions).toLocaleString()}</td>
                            </tr>
                            <tr>
                                <td>Average Transaction Amount</td>
                                <td>{`UGX ${Number(customer.average_transaction_amount).toLocaleString()}`}</td>
                            </tr>
                            <tr>
                                <td>Cum. Deposits</td>
                                <td>{`UGX ${Number(customer.average_transaction_amount).toLocaleString()}`}</td>
                            </tr>
                            <tr>
                                <td>Cum. Withdraws</td>
                                <td>{`UGX ${Number(customer.average_transaction_amount).toLocaleString()}`}</td>
                            </tr>
                            <tr>
                                <td>Cum. Interest Earned</td>
                                <td>{`UGX ${Number(customer.average_transaction_amount).toLocaleString()}`}</td>
                            </tr>
                            <tr>
                                <td>Net Disan Revenue from Customer</td>
                                <td>{`UGX ${Number(customer.average_transaction_amount).toLocaleString()}`}</td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
            </Row>
            <hr/>

            <Row>
                {/* actions for the user */}
                

            </Row>

        </React.Fragment>  
    );
};

export default Profile;

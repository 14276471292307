import React, { useEffect, useState } from "react";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input, notification
} from "antd";
// import signinbg from "../assets/images/img-signin.jpg";

import Auth from "../logic/auth";


const { Title } = Typography;
const { Footer, Content } = Layout;

export default function Login(props){
  const [fetching, setFetching] = useState(false);
  
  const SubmitLogin = async data => {
    try{
      setFetching(true);
      await new Auth(props, data).login();
      setFetching(false);
    } catch(error){
      console.log(error);
      setFetching(false);
      notification.error({
        description: "An error occurred while attempting to login",
      });
    }
  }

  useEffect(()=>{
    document.title="DiSAN Admin";
    sessionStorage.clear();
  })

  return (
    <>
      <Layout className="layout-default layout-signin" style={{backgroundColor: "#b2d2f7"}}>
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">DiSAN</Title>
              <Form
                onFinish={SubmitLogin}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your registered email",
                    },
                  ]}
                >
                  <Input placeholder="e.g. someone@example.com" />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your login password",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                    disable={fetching}
                    loading={fetching}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              {/* <img src={signinbg} alt="" /> */}
            </Col>
          </Row>
        </Content>
        <Footer>
          <p className="copyright">
            {" "}
            Copyright © 2023 -{new Date().getFullYear()} DiSAN
          </p>
        </Footer>
      </Layout>
    </>
  );
}




import React, {useEffect} from "react";

import {
  Card,
  Col,
  Row,
  Typography, Skeleton
} from "antd";

// import LineChart from "../components/chart/LineChart";
import Reports from "../logic/reports";
import { useSelector } from 'react-redux';


function Home(props) {
  const overview = useSelector((state) => state.overview);

  const { Title } = Typography;

  const count = [

    {value: 'noTotalCustomers', title: "Total customers", size: 6},
    {value: 'todayCustomers', title: "Today's customers", size: 6},
    {value: 'airtelCustomers', title: 'No. Airtel Customers', size: 6},
    {value: 'mtnCustomers', title: 'No. Mtn Customers', size: 6},

    {value: 'pendingGroups', title: "Pending Groups", size: 6},
    {value: 'inactiveGroups', title: 'Inactive Groups', size: 6},
    {value: 'activeGroups', title: 'Active Groups',  size: 6},
    {value: 'totalGroups', title: 'Total Groups', size: 6},
    
    {value: 'noSuccessfulDeposits', title: 'No. Successful Deposits', size: 5},
    {value: 'amountSuccessfulDeposits', title: 'Amt. Successful Deposits', size: 7},
    {value: 'noSuccessfulWithdraws', title: 'No. Successful Withdraws', size: 5},
    {value: 'amountSuccessfulWithdraws', title: 'Amt. Successful Withdraws', size: 7},
    
    {value: 'actualBalances', title: "Account Balances", size: 12},
    {value: 'availableBalances', title: "Amount Withdrawable", size: 12},

    {value: 'disanBalance', title: "Current Disan Balance", size: 24},
  ];


  useEffect(() => {
    document.title = "DiSAN Admin";
    new Reports(props, {}).getDashboard()
  }, [props]);

  return (
    <>
      <div className="layout-content">
        {
          overview.fetching ? 
          <Skeleton active /> :
          <Row className="rowgap-vbox" gutter={[24, 0]}>
          {count.map((c, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={c.size}
              xl={c.size}
              className="mb-24"
            >
              <Card 
                bordered={false}
                className="criclebox">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={24}>
                      <Title level={4}>
                        {Number(overview.details.data[c.value]).toLocaleString()}
                      </Title>
                      <span style={{color: 'black', fontWeight: 5}}>{c.title}</span>
                      
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
        }
        


        {/* <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <LineChart />
            </Card>
          </Col>
        </Row> */}

      </div>
    </>
  );
}

export default Home;

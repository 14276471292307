import React, {useState} from 'react';
import {
    Form, Button, Input, Table, Tag, Skeleton,
    Row, Col, Select
} from "antd";
import ServiceTickets from "../../logic/serviceTickets";
import { useSelector } from 'react-redux';
  
function ListServiceTickets(props) {
    const [showForm, setShowForm] = useState(false);
    const tickets = useSelector((state) => state.tickets);
    const [ fetching, setFetching ] = useState(false);
    return (
        <React.Fragment>
            <p onClick={() => {setShowForm(!showForm)}} style={{color: 'green'}}>
                {`${showForm ? 'Hide' : 'Show'} filter form`}
            </p>
          
          { !showForm ? null : <FilterForm {...props} setFetching={setFetching}/> }
          { tickets.list.length < 1 ? null : <ResultsTable fetching={fetching} /> }
          
        </React.Fragment>
    );
  }
  
  export default ListServiceTickets;


function FilterForm (props) {
    const submitFilter = async (data) => {
        props.setFetching(true)
        let query = `?`
        if (data.msisdn !== undefined && data.msisdn !== null) { query += `&msisdn=${data.msisdn}`}
        await new ServiceTickets(props, query ).list()
        props.setFetching(false)
    }
    return (
        <Form 
            onFinish={submitFilter}
            layout="inline"
            labelCol={{
                span: 8,
              }}
              style={{
                width: "100%",
              }}
            >

            <Row>
                <Col lg={8} sm={24} md={8}>
                    <Form.Item
                    label="Label"
                        name="label"
                        rules={[]}
                    >
                        <Select>
                            <Select.Option value="help-me-plan">Help Me Plan</Select.Option>
                            <Select.Option value="create-group">Create Group</Select.Option>
                            <Select.Option value="reset-password">Reset Password</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col lg={8} sm={24} md={8}>
                    <Form.Item
                    label="Level"
                        name="level"
                        rules={[]}
                    >
                        <Select>
                            <Select.Option value="1">1</Select.Option>
                            <Select.Option value="2">2</Select.Option>
                            <Select.Option value="3">3</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col lg={8} sm={24} md={8}>
                <Form.Item
                    label="MSISDN"
                        name="msisdn"
                        rules={[]}
                    >
                        <Input placeholder="Phone No e.g. 256706087495" />
                    </Form.Item>
                </Col>

                <Col lg={8} sm={24} md={8}>
                <Form.Item
                    label="Date Created"
                        name="date"
                        rules={[]}
                    >
                        <Input placeholder="e.g. Today" type="date" />
                    </Form.Item>
                </Col>

                <Col lg={3} sm={24} md={3}>
                <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                // disable={fetching}
                // loading={fetching}
                >
                Search
                </Button>
                </Col>
            </Row>
            
          </Form>
    )
}

function ResultsTable (props) {
    const tickets = useSelector((state) => state.tickets);
    const levelColors = {
        1: 'blue',
        2: 'orange',
        3: 'red'
    }
    const columns = [
        {title: "Customer", dataIndex: "msisdn", key: "msisdn"},
        {title: "Label", dataIndex: "label", key: "label"},
        {title: "Category", dataIndex: "category", key: "category"},
        {title: "Level", dataIndex: "level", key: "level", render: (index, record) => <span style={{color: levelColors[record.level]}}>{record.level}</span>},
        {title: "Time Opened", dataIndex: "timeOpened", key: "timeOpened", render: (index, record) => <span key={index}>{`${record.timeCreated.dayName}, ${record.timeCreated.date} ${record.timeCreated.monthName} ${record.timeCreated.year} ${record.timeCreated.hour}:${record.timeCreated.minute}`}</span>},
        // {title: "Email", dataIndex: "email", key: "email", render: (index, record) => <span>{`${record.email !== undefined ? record.email : ''}`}</span>},
        // {title: "Reg. Date", dataIndex: "reg_date", key: "reg_date", render: (index, record) => <span key={index}>{`${record.timeCreated.date} ${record.timeCreated.monthName} ${record.timeCreated.year}`}</span>},
        // {title: "Reg. Time", dataIndex: "reg_time", key: "reg_time", render: (index, record) => <span key={index}>{`${record.timeCreated.hour}:${record.timeCreated.minute}`}</span>},
        {title: "Actions", dataIndex: "actions", key: "actions", render: (index, record) => <span key={index}>
            <Tag color="green">Add call response</Tag>
            <Tag color="red">Ignore</Tag>
        </span>}
    ]
    return(

        <React.Fragment>
            {
                props.fetching ?
                <Skeleton active />
                :
                <React.Fragment>
                    <p style={{marginBottom: '10px'}}>{`${tickets.pagination.totalRecords} Result(s)`}</p>
                    <Table columns={columns} dataSource={tickets.list} pagination={false} size="small"/>
                </React.Fragment>
            }
        

        </React.Fragment>
    )
}